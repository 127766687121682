import React, { PureComponent } from "react"
import { graphql } from "gatsby"
import * as Organisms from "../components/organisms"
import { css } from "@emotion/react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import * as Libs from "libs"

class Tag extends PureComponent {
  contCss = () => css`
    ${Libs.Common.Func.getPcSpVwValue("margin-top", 350, 275, true)};
  `

  constructor(props) {
    super(props)

    this.counter = 0
  }
  componentDidMount() {
    Libs.Common.Config.excuteTransition()
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }
  render() {
    const { data, pageContext } = this.props
    const tags = data.contentfulCommonTag
    const currentSlug = pageContext.slug
    const type = pageContext.type

    const items = tags["content___" + type]
    let schemaOrgJSONLDList = []

    schemaOrgJSONLDList.push(
      Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD([
        { name: "Home", url: `/${tags.node_locale}/` },
        {
          name: tags.name,
          url: `/${tags.node_locale}/${tags.slug}/`,
        },
      ])
    )
    return (
      <>
        <Libs.Common.SEO.SEO
          title={tags.name}
          lang={tags.node_locale}
          link={tags.slug}
          schemaOrgJSONLD={schemaOrgJSONLDList}
          description={
            tags.description
              ? documentToReactComponents(JSON.parse(tags.description.raw))[0]
                  .props.children[0]
              : ""
          }
        />
        <section
          css={this.contCss}
          className={"project-container init-animat-cont"}
          data-current={tags.slug}
          data-currentname={tags.name}
          data-categoryname={type}
          data-categoryslug={type}
          data-type={"category"}
        >
          <Organisms.CategoryPage
            description={tags.description ? tags.description : null}
            type={"category"}
            items={items}
            slug={currentSlug}
          />
        </section>
      </>
    )
  }
}

export default Tag

export const pageQuery = graphql`
  query tagQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }

    contentfulCommonTag(id: { eq: $id }) {
      id
      node_locale
      name
      slug
      description {
        raw
      }
      group
      content___works {
        slug
        date
        name
        title

        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      content___journal {
        slug
        name
        date
        title
        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        node_locale
        thumbnail {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      content___shop {
        slug
        title
        name
        date

        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
